<template>
  <div>
    <filter-invoices ref="FilterInvoices" />
    <show-invoices ref="ShowInvoices" />
  </div>
</template>
<script>

  export default {
    components: {
      FilterInvoices: () => import('./components/FilterInvoices'),
      ShowInvoices: () => import('./components/ShowInvoices'),
    },
  }
</script>
